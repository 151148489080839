/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
/*@import "~@angular/material/prebuilt-themes/indigo-pink.css";*/
@import "assets/custom-theme.scss";
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';

@font-face {
  font-family: NunitoSans-Light;
  src: url(assets/fonts/NunitoSans-Light.ttf) format("truetype");
}

@font-face {
  font-family: NunitoSans-Regular;
  src: url(assets/fonts/NunitoSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: NunitoSans-ExtraBold;
  src: url(assets/fonts/NunitoSans_7pt-ExtraBold.ttf) format("truetype");
}

* {
  font-family: 'NunitoSans-Regular', serif;
}

.error-snack {
  background-color: #a80000;
  color: white;

  .mat-mdc-snack-bar-action{
    color: white;
    font-weight: bold;
  }
}

.undo-snack button {
  background-color: #0175e4;
  color: white;
}

.info-snack {
  background-color: #0175e4;
  color: white;
}

.verified-snack{
  background-color: #0175e4;
  color: white;
  font-family: 'NunitoSans-Regular', serif;
}

.dialog{
  max-width: 450px !important;
  max-height: 80vh !important;
}
//
//.dialog mat-dialog-container {
//  display: flex;
//  flex-direction: column;
//  flex: 1;
//  min-height: 0;
//  overflow: unset;
//  height: 1000px;
//  margin: unset !important;
//}
//
//.newTerms-dialog{
//  max-width: 95vw !important;
//  max-height: 80vh !important;
//}
//
//.newTerms-dialog mat-dialog-container{
//  display: flex;
//  flex-direction: column;
//  flex: 1;
//  min-height: 0;
//  overflow: unset;
//  height: 1000px;
//  margin: unset !important;
//  padding: 24px 0px;
//  max-width: 500px !important;
//}
//
//.terms-dialog{
//  max-width: 95vw !important;
//  max-height: 80vh !important;
//}
//
//.terms-dialog mat-dialog-container{
//  display: flex;
//  flex-direction: column;
//  flex: 1;
//  overflow: unset;
//  max-width: 800px !important;
//  min-height: 300px;
//  margin: unset !important;
//  font-family: 'NunitoSans-Regular', serif;
//}
//
//.cookie-dialog{
//  max-width: 95vw !important;
//  max-height: 80vh !important;
//}
//
//.cookie-dialog mat-dialog-container{
//  display: flex;
//  flex-direction: column;
//  flex: 1;
//  max-width: 450px !important;
//  min-height: 300px;
//  margin: unset !important;
//  font-family: 'NunitoSans-Regular', serif;
//}

//.mat-mdc-menu-panel.userMenu{
//    width: 240px;
//}

.userMenu {
  display: flex;
  flex-direction: column;

  .userMenuItem {
    display: flex;
    flex-direction: column;
    padding: 5px 25px;
    margin: 5px 0px;
    cursor: pointer;

    .primarySpan {
      font-family: 'NunitoSans-Regular', serif;
    }

    .mat-icon{
        margin-left: auto;
    }
  }

  .userMenuItem:hover {
    background-color: #E6E6E6;
  }
}

.mat-mdc-menu-panel.threeDotMenu{
    width: 240px;
}

.threeDotMenu{

    .mat-mdc-menu-content{
        display: flex;
        flex-direction: column;

        .menuItem{
            display: flex;
            padding: 5px 25px;
          font-family: 'NunitoSans-Regular', serif;

            .mat-icon{
                margin-left: auto;
            }
        }

        .menuItem:hover{
            background-color: #E6E6E6;
            cursor: pointer;
        }
    }
}

.mat-mdc-standard-chip{
  background: none !important;
}

.mat-mdc-chip.mat-mdc-standard-chip::after {
  background: none !important;
}

a{
  color: #0175E4;
  text-decoration: none;
}

a:visited {
  text-decoration: none;
  color: #0175E4;
}
